/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap');

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.font-robo{
  font-family: 'Roboto Condensed', sans-serif;
}

.font-vuja{
  font-family: 'Vujahday Script', cursive;
}
.bg-dark {
  background-color: #000!important;
}

.table thead th{
  border-top: none;
}
.breadcrumb{
  background-color: #f3f3f3;
}
.breadcrumb-item a{
  color: #6f777f;
}

.breadcrumb-item.active{
  color: #28a745;
}

.btn-more-tour{
  display: flex;
    color: orange;
}
.btn-more-tour mat-icon{
  color: #1aa99e;
}

.card{
  height: 100%;
}
